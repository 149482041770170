import React, {
  CSSProperties,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from 'react'
import {
  BaseLinkProps,
  GenericMenuLink,
  baseLinkCss,
} from 'components/presentational/GenericMenuItemIndex/GenericMenuItemIndex.styled'
import { GenericMenuItemViewNotEditable } from 'components/presentational/GenericMenuItemIndex/GenericMenuItemViewNotEditable'
import { GenericMenuItemContent } from 'components/presentational/GenericMenuItemIndex/GenericMenuItemContent'
import { WithDataName } from 'common/types'
import styled from 'styled-components'

export const MenuContainer = styled.div<BaseLinkProps>`
  ${baseLinkCss};
`

export const MenuContainerLabel = styled.label<BaseLinkProps>`
  ${baseLinkCss};
`

export const MenuPlainLink = styled.a<BaseLinkProps>`
  ${baseLinkCss};
`

export interface GenericMenuItemIndexProps {
  to?: string
  href?: string
  replace?: boolean
  details?: ReactNode
  control?: ReactNode | boolean
  info?: ReactNode
  label?: boolean
  infoId?: string
  hideArrow?: boolean
  smallText?: string
  disabled?: boolean
  counter?: boolean
  iconItem?: ReactNode
  className?: string
  onClick?: MouseEventHandler<HTMLElement>
  target?: unknown
  // TODO: я не понимаю как и зачем он нужен надо разобраться и либо переделать, либо удалить.
  active?: boolean | null
  isOverflowText?: boolean
  style?: CSSProperties
  id?: string
}

export const GenericMenuItemIndex: FC<
  GenericMenuItemIndexProps &
    WithDataName & { colorLink?: string; target?: string } & PropsWithChildren
> = ({
  to,
  href,
  replace = false,
  children,
  label = true,
  smallText = true,
  disabled = false,
  iconItem,
  className,
  onClick,
  target,
  active = true,
  style,
  id,
  ...rest
}) => {
  const properties: {
    activelink: 'active' | 'inActive'
    text: 'small' | 'big'
    ['data-name']?: string
    className?: string
    disabled: boolean
    $colorLink?: string
    style?: CSSProperties
    replace: boolean
  } = {
    text: smallText ? 'small' : 'big',
    ['data-name']: rest['data-name'],
    className,
    activelink: active ? 'active' : 'inActive',
    disabled,
    $colorLink: rest.colorLink,
    style,
    replace,
  }

  const fragment = (
    <GenericMenuItemContent
      {...rest}
      to={to}
      iconItem={iconItem}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </GenericMenuItemContent>
  )

  if (!to && !href && !onClick && !label && iconItem) {
    return (
      <GenericMenuItemViewNotEditable
        iconItem={iconItem}
        data-name={properties['data-name']}
        style={properties['style']}
      >
        {children}
      </GenericMenuItemViewNotEditable>
    )
  }

  if (href) {
    return (
      <MenuPlainLink href={href} target={target} {...properties}>
        {fragment}
      </MenuPlainLink>
    )
  }

  if (to && !disabled) {
    return (
      <GenericMenuLink to={to} onClick={onClick} {...properties}>
        {fragment}
      </GenericMenuLink>
    )
  }

  if (label) {
    return (
      <MenuContainerLabel onClick={onClick} id={id} {...properties}>
        {fragment}
      </MenuContainerLabel>
    )
  }

  return (
    <MenuContainer onClick={onClick} {...properties}>
      {fragment}
    </MenuContainer>
  )
}
