import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { settingsReducer } from 'reducers/settings/settingsReducer'

export const useShowTooltip = (infoId?: string) => {
  useReducersInsert({ settingsReducer: settingsReducer })
  const { info } = useShallowEqualSelector(({ settingsReducer: { info } }) => ({
    info,
  }))
  return infoId ? info[infoId] : false
}
