import { MenuLabel } from 'components/presentational/GenericMenuItemIndex/GenericMenuLabel'
import {
  StyledContent,
  StyledDetails,
  StyledGroup,
  StyledIcon,
} from 'components/presentational/GenericMenuItemIndex/GenericMenuItemIndex.styled'
import { BottomTooltip } from 'components/presentational/Tooltip'
import { toggleSettingsInfoAction } from 'actions/settings/toggleSettingsInfoAction'
import { ArrowRightSvg } from 'components/presentational/svg/ArrowRightSvg'
import { LockSvg } from 'components/presentational/svg/LockSvg'
import { arrowSvgColor } from 'common/constants'
import React, {
  FC,
  ReactChild,
  ReactNode,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
} from 'react'
import { useDispatch } from 'react-redux'
import { useShowTooltip } from 'components/presentational/GenericMenuItemIndex/useShowTooltip'
import { Colors } from 'common-constants/colors'
import { useGenericMenuItemContext } from 'components/presentational/GenericMenuItemIndex/GenericMenuItemContext'

interface GenericMenuItemContentProps extends PropsWithChildren {
  infoId?: string
  iconItem?: ReactNode
  details?: ReactNode
  info?: ReactNode
  counter?: boolean
  control?: ReactNode | boolean
  to?: string
  hideArrow?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLElement>
  isOverflowText?: boolean
}

export const GenericMenuItemContent: FC<GenericMenuItemContentProps> = ({
  infoId,
  iconItem,
  children,
  details,
  info,
  counter,
  control,
  to,
  hideArrow,
  disabled,
  onClick,
  isOverflowText = true,
}) => {
  const dispatch = useDispatch()
  const showInfoTooltip = useShowTooltip(infoId)
  const { styleCustomControl } = useGenericMenuItemContext()

  return (
    <>
      <MenuLabel infoId={infoId} showInfoTooltip={showInfoTooltip}>
        {Boolean(iconItem) && iconItem}
        {isOverflowText && <StyledContent>{children}</StyledContent>}
        {!isOverflowText && children}
        {details && <StyledDetails>{details}</StyledDetails>}
      </MenuLabel>
      {showInfoTooltip && (
        <BottomTooltip
          onClick={(event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            dispatch(toggleSettingsInfoAction(infoId!))
          }}
          data-name={`${infoId}-tooltip`}
        >
          {info}
        </BottomTooltip>
      )}
      {Boolean(counter) && counter}
      <StyledGroup style={styleCustomControl}>
        {control}
        {to && !hideArrow && !disabled && (
          <StyledIcon>
            <ArrowRightSvg fill={arrowSvgColor} />
          </StyledIcon>
        )}
        {onClick && !hideArrow && (
          <StyledIcon>
            <ArrowRightSvg fill={arrowSvgColor} />
          </StyledIcon>
        )}
        {(to || onClick) && disabled && (
          <StyledIcon>
            <LockSvg fill={Colors.noteColor} />
          </StyledIcon>
        )}
      </StyledGroup>
    </>
  )
}
