import { useDispatch } from 'react-redux'
import {
  StyledInfo,
  StyledLabel,
} from 'components/presentational/GenericMenuItemIndex/GenericMenuItemIndex.styled'
import { toggleSettingsInfoAction } from 'actions/settings/toggleSettingsInfoAction'
import { Info2Svg } from 'components/presentational/svg/Info2Svg'
import { SmallClose2Svg } from 'components/presentational/svg/SmallClose2Svg'
import React, { FC, PropsWithChildren } from 'react'

export interface MenuLabelProps extends PropsWithChildren {}

export const MenuLabel: FC<
  {
    info?: unknown
    infoId?: string
    showInfoTooltip: boolean
  } & PropsWithChildren
> = ({ children, info, infoId, showInfoTooltip }) => {
  const dispatch = useDispatch()

  return (
    <StyledLabel>
      {children}
      {info ? (
        <StyledInfo
          onClick={(event) => {
            event.preventDefault()
            dispatch(toggleSettingsInfoAction(infoId!))
          }}
          data-name={`toggle-${infoId}-action`}
        >
          {showInfoTooltip ? <SmallClose2Svg /> : <Info2Svg />}
        </StyledInfo>
      ) : undefined}
    </StyledLabel>
  )
}
