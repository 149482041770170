import React, { FC, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { Paper, PaperCss } from 'components/presentational/separator'
import { Colors } from 'common-constants/colors'
import { CommonHandler, WithDataName } from 'common/types'
import { media } from '.'

export const zIndexTooltip = 3

const TooltipCss = css`
  position: absolute;
  ${(props) => props.theme.left}: 0;

  width: 100%;
  z-index: ${zIndexTooltip};
`

const TooltipMoreCss = css`
  ${TooltipCss};
  ${(props) => props.theme.left}: -5px;
`

const BottomWrapper = styled.div`
  ${TooltipCss};
`

const BottomWrapperMambaMore = styled.div`
  ${TooltipMoreCss};
  top: 25px;
`

const BottomWrapperPartnerMore = styled.div`
  ${TooltipMoreCss};
  top: 17px;
`

const Container = styled.div`
  position: absolute;
  top: 0;
  ${(props) => props.theme.left}: 0;
`

export const BottomTooltip: FC<
  { onClick: CommonHandler } & WithDataName & PropsWithChildren
> = ({ onClick, children, ...rest }) => (
  <BottomWrapper onClick={onClick}>
    <Container data-name={rest['data-name']}>{children}</Container>
  </BottomWrapper>
)

export const BottomTooltipMambaMore: FC<PropsWithChildren> = ({ children }) => (
  <BottomWrapperMambaMore>
    <Container>{children}</Container>
  </BottomWrapperMambaMore>
)

export const BottomTooltipPartnerMore: FC<PropsWithChildren> = ({
  children,
}) => (
  <BottomWrapperPartnerMore>
    <Container>{children}</Container>
  </BottomWrapperPartnerMore>
)

// TODO привести к общему виду
export const CenterTooltip = styled.div<{ top?: string }>`
  ${TooltipCss};
  display: flex;
  flex-direction: column;
  align-items: center;
  top: ${(props) => (props.top ? `calc(50% - ${props.top})` : '15px')};
`

export const AnimatedHoverOpacityEffect = styled.div`
  ${CenterTooltip} {
    opacity: 0;
    pointer-events: none;
    transition: opacity ease 300ms;
  }

  @media (hover: hover) {
    &:hover ${CenterTooltip} {
      opacity: 1;
    }
  }
`

export const CenterTopTooltip = styled(BottomWrapper)`
  ${TooltipCss};
  display: flex;
  justify-content: center;
  bottom: 100%;
`

export const rightTopCss = css<{
  $minWidth?: string
}>`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  ${(props) => props.theme.right}: 100%;
  ${(props) => props.theme.left}: auto;
  min-width: ${(props) => (props.$minWidth ? props.$minWidth : 'inherit')};
`

export const RightTopTooltip = styled(BottomWrapper)<{
  $minWidth?: string
}>`
  top: 0;
  ${rightTopCss};
`

export const RightBottomTooltip = styled(BottomWrapper)<{ $minWidth?: string }>`
  ${rightTopCss};
  ${(props) => props.theme.right}: 0;
`

export const CustomRightTopTooltip = styled(BottomWrapper)`
  min-width: 300px;
  min-width: max-content;
  bottom: 100%;
  ${(props) => props.theme.right}: 0;
  ${(props) => props.theme.left}: auto;
`

const Content = styled(Paper)`
  font-size: 12px;
  padding: 11px 15px 13px 15px;
  box-sizing: content-box;
  color: ${Colors.black};
`
const Title = styled.h4`
  margin: 0 0 10px;
`
const Text = styled.p`
  margin: 0;
`

export const TooltipText: FC<{ title: string } & PropsWithChildren> = ({
  title,
  children,
}) => (
  <Content>
    {title && <Title>{title}</Title>}
    {children && <Text>{children}</Text>}
  </Content>
)

export const TooltipWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Content>{children}</Content>
)

export const TooltipTitle: FC<PropsWithChildren> = ({ children }) => (
  <Title>{children}</Title>
)

export const TooltipContent = styled(Content)``

export const TooltipPlaceholder = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

export const TooltipDefaultContentCss = css`
  ${PaperCss};
  position: relative;
  z-index: ${zIndexTooltip};
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding: 12px 15px;
  outline: 0;
`

export const TooltipDefaultContent = styled.div`
  ${TooltipDefaultContentCss};
`

/** Редизайн */
export const TooltipContentNext = styled.div`
  ${TooltipDefaultContentCss};
  padding: 0;
  min-width: 260px;
  text-align: center;
`

/** Контейнер для обычных кнопок в тултипе */
export const TooltipBlockDefault = styled.div`
  padding: 10px 0;
`

/** Контейнер для кнопок удалить/отмена в тултипе */
export const TooltipBlockWarning = styled(TooltipBlockDefault)`
  border-top: 1px solid ${Colors.tooltipInnerBorder};
`

export const TooltipCustomContent = styled.div`
  display: flex;
  ${TooltipDefaultContentCss};
  padding: 0;
  flex-direction: row;
  width: 300px;
  background-color: transparent;
  box-shadow: none;

  ${media.phone`        
    max-width: max-content;
  `}
`

export const TooltipDefaultContentItem = styled.div`
  padding: 5px 0;
`

export const TooltipComplaintListItem = styled.div`
  & > span {
    display: flex;
    padding: 5px 0;

    ${media.tabletLarge`
      padding: 8px 0
    `}
  }
`

export const ListComplaints = styled.div`
  min-width: 100%;
  padding: 12px 15px;
  background-color: ${Colors.white};
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease-in-out;
`
