import React, { FC } from 'react'

export const Info2Svg: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#000"
      strokeOpacity=".4"
      strokeWidth="1.6"
    >
      <path d="M8 7.5v4m0-7.1V6" />
      <circle cx="8" cy="8" r="6.2" />
    </g>
  </svg>
)
