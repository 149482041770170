import { Colors } from 'common-constants/colors'
import { FontSize } from 'common-constants/sizes'
import { invisibleSvgBgColor } from 'common/constants'
import { textOverflowStyles } from 'common/styles/textOverflow'
import { media } from 'components/presentational'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const GroupCss = css`
  display: flex;
  align-items: center;
  color: ${Colors.noteColor};
  padding-left: 8px;
`

export const StyledGroup = styled.div`
  ${GroupCss}
`

export const GroupGreeting = styled.div`
  display: none;

  ${media.tabletLarge`
    ${GroupCss};
  `}
`

export const StyledLabelCss = css`
  display: flex;
  overflow: hidden;
`

export const StyledLabel = styled.div`
  ${StyledLabelCss};
  align-items: center;
`

export const StyledLabelGreeting = styled.div`
  ${StyledLabelCss};
  align-items: flex-start;
`

export const StyledContent = styled.div`
  ${textOverflowStyles};
`

export const ContentGreetingWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const IconEditWrapperInline = styled.div`
  display: inline-block;
  vertical-align: bottom;
  ${(props) => props.theme.marginLeft}: 5px;

  ${media.tabletLarge`
    display: none;
  `}
`

export const StyledInfo = styled.div`
  display: flex;
  ${(props) => props.theme.marginLeft}: 8px;
  cursor: pointer;
`

export const StyledDetails = styled.div`
  max-width: 120px;
  ${textOverflowStyles};
`

export const StyledIcon = styled.div`
  ${(props) => props.theme.marginLeft}: 6px;
`

export const StyledIconWrapper = styled.div`
  ${(props) => props.theme.paddingRight}: 10px;
  display: flex;
  margin-bottom: auto;
`

export const StyledMenuItemGrouped = styled.div`
  padding-bottom: 20px;
`

export interface DisabledProps {
  disabled?: boolean
}

// TODO: не могу понять где у нас может быть черный цвет в меню
// По идее только два цвета (Colors.link - обычный или Colors.invisibleSvg - активный)
export const checkIfDisabled = (color: Colors) => (props: DisabledProps) =>
  props.disabled ? Colors.black : color

const menuItemCss = css<{ disabled?: boolean }>`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-width: 0;
  min-height: 48px;
  -webkit-appearance: none;
  background-color: transparent;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : ' pointer')};

  &:last-child {
    border-bottom-width: 0;
  }
`

export const menuLinkCss = css<DisabledProps>`
  color: ${() => checkIfDisabled(Colors.link)};

  &:focus,
  &:hover {
    color: ${checkIfDisabled(Colors.linkHover)};
  }
  &:active {
    color: ${checkIfDisabled(Colors.checkboxActive)};
  }

  ${menuItemCss}
`

export const GenericMenu = styled.div<{ disabled?: boolean }>`
  position: relative;
  font-size: ${FontSize.titleName}px;
  color: ${Colors.invisibleSvgBackground};
  ${menuItemCss};
  cursor: default;
  align-items: center;
  justify-content: flex-start;
`

export type BaseLinkProps = {
  activelink?: 'active' | 'inActive'
  disabled?: boolean
  $colorLink?: string
}

export const baseLinkCss = css<BaseLinkProps>`
  position: relative;
  font-size: ${FontSize.titleName}px;

  ${menuItemCss};

  color: ${(props) => {
    if (props.disabled) {
      return Colors.specialNotFoundGray
    }

    if (props.$colorLink) {
      return props.$colorLink
    }

    if (props.activelink === 'active') {
      return checkIfDisabled(Colors.dark)
    }

    return checkIfDisabled(Colors.link)
  }};

  &:focus {
    color: ${(props) => {
      if (props.activelink === 'active') {
        return Colors.dark
      }

      if (props.disabled) {
        return Colors.specialNotFoundGray
      }

      if (props.$colorLink) {
        return props.$colorLink
      }

      return checkIfDisabled(Colors.linkHover)
    }};
  }

  @media (hover: hover) {
    &:hover {
      color: ${(props) => {
        if (props.activelink === 'active') {
          return Colors.dark
        }

        if (props.disabled) {
          return Colors.specialNotFoundGray
        }

        if (props.$colorLink) {
          return props.$colorLink
        }

        return checkIfDisabled(Colors.linkHover)
      }};
    }
  }

  &:active {
    color: ${(props) => {
      if (props.activelink === 'active') {
        return Colors.dark
      }

      if (props.disabled) {
        return Colors.specialNotFoundGray
      }

      if (props.$colorLink) {
        return props.$colorLink
      }

      return checkIfDisabled(Colors.checkboxActive)
    }};
  }
`

export const GenericMenuLink = styled(Link)<BaseLinkProps>`
  ${baseLinkCss};
`

export const MenuLink = styled(Link)<DisabledProps>`
  ${menuLinkCss};
`

export const MenuButtonElement = styled.div<BaseLinkProps>`
  ${baseLinkCss};
  align-items: center;
  text-align: left;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  color: ${({ color, disabled }) =>
    color || (disabled ? Colors.noteColor : invisibleSvgBgColor)};
` // Ломается флекс в iOS10, поэтому имитируем кнопку
