import { createContext, CSSProperties, useContext } from 'react'

interface GenericMenuItemContext {
  styleCustomControl: CSSProperties | undefined
}

export const GenericMenuItemContext = createContext<GenericMenuItemContext>({
  styleCustomControl: undefined,
})

export const useGenericMenuItemContext = (): GenericMenuItemContext => {
  return useContext(GenericMenuItemContext)
}

export const styleCustomControl: CSSProperties = {
  padding: 0,
  minWidth: '24px',
}
