import React, { FC } from 'react'

export const SmallClose2Svg: FC<{
  stroke?: string
  fill?: string
  fillOpacity?: string
}> = ({ stroke = 'white', fill = 'black', fillOpacity = '.4' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="7" fill={fill} fillOpacity={fillOpacity} />
      <path
        stroke={stroke}
        strokeLinecap="square"
        strokeWidth="1.6"
        d="M6 6l4 4m0-4l-4 4"
      />
    </g>
  </svg>
)
