import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'
import {
  GenericMenu,
  StyledDetails,
} from 'components/presentational/GenericMenuItemIndex/GenericMenuItemIndex.styled'
import { WithDataName } from 'common/types'

export const GenericMenuItemViewNotEditable: FC<
  {
    iconItem: ReactNode
    details?: ReactNode
    style?: CSSProperties
  } & WithDataName &
    PropsWithChildren
> = ({ children, iconItem, details, style, ...rest }) => (
  <GenericMenu data-name={rest['data-name']} style={style}>
    {Boolean(iconItem) && iconItem}
    <>{children}</>
    {details && <StyledDetails>{details}</StyledDetails>}
  </GenericMenu>
)
